define("discourse/plugins/chat/discourse/components/chat-channel-preview-card", ["exports", "@glimmer/component", "@ember/helper", "@ember/routing", "@ember/service", "@ember/utils", "discourse/helpers/concat-class", "discourse-i18n", "discourse/plugins/chat/discourse/components/channel-title", "discourse/plugins/chat/discourse/components/toggle-channel-membership-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _routing, _service, _utils, _concatClass, _discourseI18n, _channelTitle, _toggleChannelMembershipButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatChannelPreviewCard extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    get showJoinButton() {
      return this.args.channel?.isOpen && this.args.channel?.canJoin;
    }
    get hasDescription() {
      return !(0, _utils.isEmpty)(this.args.channel?.description);
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class={{concatClass
            "chat-channel-preview-card"
            (unless this.hasDescription "-no-description")
            (unless this.showJoinButton "-no-button")
          }}
        >
          <ChannelTitle @channel={{@channel}} />
          {{#if this.hasDescription}}
            <p class="chat-channel-preview-card__description">
              {{@channel.description}}
            </p>
          {{/if}}
          {{#if this.showJoinButton}}
            <ToggleChannelMembershipButton
              @channel={{@channel}}
              @options={{hash joinClass="btn-primary"}}
            />
          {{/if}}
          <LinkTo
            @route="chat.browse"
            class="chat-channel-preview-card__browse-all"
          >
            {{i18n "chat.browse_all_channels"}}
          </LinkTo>
        </div>
      
    */
    {
      "id": "+ibmjO0y",
      "block": "[[[1,\"\\n    \"],[10,0],[15,0,[28,[32,0],[\"chat-channel-preview-card\",[52,[51,[30,0,[\"hasDescription\"]]],\"-no-description\"],[52,[51,[30,0,[\"showJoinButton\"]]],\"-no-button\"]],null]],[12],[1,\"\\n      \"],[8,[32,1],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n\"],[41,[30,0,[\"hasDescription\"]],[[[1,\"        \"],[10,2],[14,0,\"chat-channel-preview-card__description\"],[12],[1,\"\\n          \"],[1,[30,1,[\"description\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showJoinButton\"]],[[[1,\"        \"],[8,[32,2],null,[[\"@channel\",\"@options\"],[[30,1],[28,[32,3],null,[[\"joinClass\"],[\"btn-primary\"]]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[8,[32,4],[[24,0,\"chat-channel-preview-card__browse-all\"]],[[\"@route\"],[\"chat.browse\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,5],[\"chat.browse_all_channels\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@channel\"],false,[\"unless\",\"if\"]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-channel-preview-card.js",
      "scope": () => [_concatClass.default, _channelTitle.default, _toggleChannelMembershipButton.default, _helper.hash, _routing.LinkTo, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatChannelPreviewCard;
});