define("discourse/plugins/chat/discourse/components/chat/modal/archive-channel", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/template", "@ember/utils", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/ajax-error", "discourse/lib/later", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat-to-topic-selector", "discourse/plugins/chat/discourse/models/chat-channel", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _template, _utils, _dButton, _dModal, _ajaxError, _later, _discourseI18n, _chatToTopicSelector, _chatChannel, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatModalArchiveChannel extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "selection", [_tracking.tracked], function () {
      return _chatToTopicSelector.NEW_TOPIC_SELECTION;
    }))();
    #selection = (() => (dt7948.i(this, "selection"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "saving", [_tracking.tracked], function () {
      return false;
    }))();
    #saving = (() => (dt7948.i(this, "saving"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "topicTitle", [_tracking.tracked], function () {
      return null;
    }))();
    #topicTitle = (() => (dt7948.i(this, "topicTitle"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "categoryId", [_tracking.tracked], function () {
      return null;
    }))();
    #categoryId = (() => (dt7948.i(this, "categoryId"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "tags", [_tracking.tracked], function () {
      return null;
    }))();
    #tags = (() => (dt7948.i(this, "tags"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "selectedTopicId", [_tracking.tracked], function () {
      return null;
    }))();
    #selectedTopicId = (() => (dt7948.i(this, "selectedTopicId"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "flash", [_tracking.tracked]))();
    #flash = (() => (dt7948.i(this, "flash"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "flashType", [_tracking.tracked]))();
    #flashType = (() => (dt7948.i(this, "flashType"), void 0))();
    get channel() {
      return this.args.model.channel;
    }
    get newTopic() {
      return this.selection === _chatToTopicSelector.NEW_TOPIC_SELECTION;
    }
    get existingTopic() {
      return this.selection === _chatToTopicSelector.EXISTING_TOPIC_SELECTION;
    }
    get buttonDisabled() {
      if (this.saving) {
        return true;
      }
      if (this.newTopic && (!this.topicTitle || this.topicTitle.length < this.siteSettings.min_topic_title_length || this.topicTitle.length > this.siteSettings.max_topic_title_length)) {
        return true;
      }
      if (this.existingTopic && (0, _utils.isEmpty)(this.selectedTopicId)) {
        return true;
      }
      return false;
    }
    get instructionLabels() {
      const labels = {};
      labels[_chatToTopicSelector.NEW_TOPIC_SELECTION] = (0, _discourseI18n.i18n)("chat.selection.new_topic.instructions_channel_archive");
      labels[_chatToTopicSelector.EXISTING_TOPIC_SELECTION] = (0, _discourseI18n.i18n)("chat.selection.existing_topic.instructions_channel_archive");
      return labels;
    }
    get instructionsText() {
      return (0, _template.htmlSafe)((0, _discourseI18n.i18n)("chat.channel_archive.instructions", {
        channelTitle: this.channel.escapedTitle
      }));
    }
    get data() {
      const data = {
        type: this.selection
      };
      if (this.newTopic) {
        data.title = this.topicTitle;
        data.category_id = this.categoryId;
        data.tags = this.tags;
      }
      if (this.existingTopic) {
        data.topic_id = this.selectedTopicId;
      }
      return data;
    }
    archiveChannel() {
      this.saving = true;
      return this.chatApi.createChannelArchive(this.channel.id, this.data).then(() => {
        this.flash = (0, _discourseI18n.i18n)("chat.channel_archive.process_started");
        this.flashType = "success";
        this.channel.status = _chatChannel.CHANNEL_STATUSES.archived;
        (0, _later.default)(() => {
          this.args.closeModal();
        }, 3000);
      }).catch(_ajaxError.popupAjaxError).finally(() => this.saving = false);
    }
    static #_11 = (() => dt7948.n(this.prototype, "archiveChannel", [_object.action]))();
    newTopicSelected(topic) {
      this.selectedTopicId = topic.id;
    }
    static #_12 = (() => dt7948.n(this.prototype, "newTopicSelected", [_object.action]))();
    static #_13 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @closeModal={{@closeModal}}
          class="chat-modal-archive-channel"
          @inline={{@inline}}
          @title={{i18n "chat.channel_archive.title"}}
          @flash={{this.flash}}
          @flashType={{this.flashType}}
        >
          <:body>
            <p class="chat-modal-archive-channel__instructions">
              {{this.instructionsText}}
            </p>
            <ChatToTopicSelector
              @selection={{this.selection}}
              @topicTitle={{this.topicTitle}}
              @categoryId={{this.categoryId}}
              @tags={{this.tags}}
              @topicChangedCallback={{this.newTopicSelected}}
              @instructionLabels={{this.instructionLabels}}
              @allowNewMessage={{false}}
            />
          </:body>
          <:footer>
            <DButton
              @disabled={{this.buttonDisabled}}
              @action={{this.archiveChannel}}
              @label="chat.channel_archive.title"
              id="chat-confirm-archive-channel"
              class="btn-primary"
            />
          </:footer>
        </DModal>
      
    */
    {
      "id": "1JxShV/b",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"chat-modal-archive-channel\"]],[[\"@closeModal\",\"@inline\",\"@title\",\"@flash\",\"@flashType\"],[[30,1],[30,2],[28,[32,1],[\"chat.channel_archive.title\"],null],[30,0,[\"flash\"]],[30,0,[\"flashType\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,2],[14,0,\"chat-modal-archive-channel__instructions\"],[12],[1,\"\\n          \"],[1,[30,0,[\"instructionsText\"]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[32,2],null,[[\"@selection\",\"@topicTitle\",\"@categoryId\",\"@tags\",\"@topicChangedCallback\",\"@instructionLabels\",\"@allowNewMessage\"],[[30,0,[\"selection\"]],[30,0,[\"topicTitle\"]],[30,0,[\"categoryId\"]],[30,0,[\"tags\"]],[30,0,[\"newTopicSelected\"]],[30,0,[\"instructionLabels\"]],false]],null],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,3],[[24,1,\"chat-confirm-archive-channel\"],[24,0,\"btn-primary\"]],[[\"@disabled\",\"@action\",\"@label\"],[[30,0,[\"buttonDisabled\"]],[30,0,[\"archiveChannel\"]],\"chat.channel_archive.title\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@inline\"],false,[]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/modal/archive-channel.js",
      "scope": () => [_dModal.default, _discourseI18n.i18n, _chatToTopicSelector.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatModalArchiveChannel;
});