define("discourse/plugins/chat/discourse/components/chat/drawer-routes/channel-threads", ["exports", "@glimmer/component", "@ember/service", "@ember/template", "discourse/helpers/replace-emoji", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/chat-thread-list", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _template, _replaceEmoji, _discourseI18n, _navbar, _chatThreadList, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatDrawerRoutesChannelThreads extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    backLinkTitle = (() => (0, _discourseI18n.i18n)("chat.return_to_list"))();
    get title() {
      return (0, _template.htmlSafe)((0, _discourseI18n.i18n)("chat.threads.list") + " - " + (0, _replaceEmoji.default)(this.args.model.channel.title));
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="c-drawer-routes --channel-threads">
          {{#if @model}}
            <Navbar @onClick={{this.chat.toggleDrawer}} as |navbar|>
              <navbar.BackButton
                @title={{this.backLinkTitle}}
                @route="chat.channel"
                @routeModels={{@model.channel.routeModels}}
              />
              <navbar.Title @title={{this.title}} @icon="discourse-threads" />
              <navbar.Actions as |a|>
                <a.ToggleDrawerButton />
                <a.FullPageButton />
                <a.CloseDrawerButton />
              </navbar.Actions>
            </Navbar>
    
            {{#if this.chatStateManager.isDrawerExpanded}}
              <div class="chat-drawer-content">
                <ChatThreadList
                  @channel={{@model.channel}}
                  @includeHeader={{false}}
                />
              </div>
            {{/if}}
          {{/if}}
        </div>
      
    */
    {
      "id": "yDS3g6KH",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-drawer-routes --channel-threads\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[8,[32,0],null,[[\"@onClick\"],[[30,0,[\"chat\",\"toggleDrawer\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[30,2,[\"BackButton\"]],null,[[\"@title\",\"@route\",\"@routeModels\"],[[30,0,[\"backLinkTitle\"]],\"chat.channel\",[30,1,[\"channel\",\"routeModels\"]]]],null],[1,\"\\n          \"],[8,[30,2,[\"Title\"]],null,[[\"@title\",\"@icon\"],[[30,0,[\"title\"]],\"discourse-threads\"]],null],[1,\"\\n          \"],[8,[30,2,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[30,3,[\"ToggleDrawerButton\"]],null,null,null],[1,\"\\n            \"],[8,[30,3,[\"FullPageButton\"]],null,null,null],[1,\"\\n            \"],[8,[30,3,[\"CloseDrawerButton\"]],null,null,null],[1,\"\\n          \"]],[3]]]]],[1,\"\\n        \"]],[2]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"          \"],[10,0],[14,0,\"chat-drawer-content\"],[12],[1,\"\\n            \"],[8,[32,1],null,[[\"@channel\",\"@includeHeader\"],[[30,1,[\"channel\"]],false]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@model\",\"navbar\",\"a\"],false,[\"if\"]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/drawer-routes/channel-threads.js",
      "scope": () => [_navbar.default, _chatThreadList.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatDrawerRoutesChannelThreads;
});