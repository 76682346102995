define("discourse/plugins/chat/discourse/components/chat/footer/unread-indicator", ["exports", "@glimmer/component", "@ember/service", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _service, _component2, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.UnreadThreadsIndicator = _exports.UnreadDirectMessagesIndicator = _exports.UnreadChannelsIndicator = void 0;
  const CHANNELS_TAB = "channels";
  const DMS_TAB = "dms";
  const THREADS_TAB = "threads";
  const MAX_UNREAD_COUNT = 99;
  const UnreadChannelsIndicator = _exports.UnreadChannelsIndicator = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <FooterUnreadIndicator @badgeType={{CHANNELS_TAB}} />
  
  */
  {
    "id": "mQFOidR1",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@badgeType\"],[[32,1]]],null],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/footer/unread-indicator.js",
    "scope": () => [FooterUnreadIndicator, CHANNELS_TAB],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "unread-indicator:UnreadChannelsIndicator"));
  const UnreadDirectMessagesIndicator = _exports.UnreadDirectMessagesIndicator = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <FooterUnreadIndicator @badgeType={{DMS_TAB}} />
  
  */
  {
    "id": "mQFOidR1",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@badgeType\"],[[32,1]]],null],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/footer/unread-indicator.js",
    "scope": () => [FooterUnreadIndicator, DMS_TAB],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "unread-indicator:UnreadDirectMessagesIndicator"));
  const UnreadThreadsIndicator = _exports.UnreadThreadsIndicator = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <FooterUnreadIndicator @badgeType={{THREADS_TAB}} />
  
  */
  {
    "id": "mQFOidR1",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@badgeType\"],[[32,1]]],null],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/footer/unread-indicator.js",
    "scope": () => [FooterUnreadIndicator, THREADS_TAB],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "unread-indicator:UnreadThreadsIndicator"));
  class FooterUnreadIndicator extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chatTrackingStateManager", [_service.service]))();
    #chatTrackingStateManager = (() => (dt7948.i(this, "chatTrackingStateManager"), void 0))();
    badgeType = this.args.badgeType;
    get urgentCount() {
      if (this.badgeType === CHANNELS_TAB) {
        return this.chatTrackingStateManager.publicChannelMentionCount;
      } else if (this.badgeType === DMS_TAB) {
        return this.chatTrackingStateManager.directMessageUnreadCount + this.chatTrackingStateManager.directMessageMentionCount;
      } else if (this.badgeType === THREADS_TAB) {
        return this.chatTrackingStateManager.watchedThreadsUnreadCount;
      } else {
        return 0;
      }
    }
    get unreadCount() {
      if (this.badgeType === CHANNELS_TAB) {
        return this.chatTrackingStateManager.publicChannelUnreadCount;
      } else if (this.badgeType === THREADS_TAB) {
        return this.chatTrackingStateManager.hasUnreadThreads ? 1 : 0;
      } else {
        return 0;
      }
    }
    get showUrgent() {
      return this.urgentCount > 0;
    }
    get showUnread() {
      return this.unreadCount > 0;
    }
    get urgentBadgeCount() {
      let totalCount = this.urgentCount;
      return totalCount > MAX_UNREAD_COUNT ? `${MAX_UNREAD_COUNT}+` : totalCount;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showUrgent}}
          <div class="c-unread-indicator -urgent">
            <div class="c-unread-indicator__number">
              {{this.urgentBadgeCount}}
            </div>
          </div>
        {{else if this.showUnread}}
          <div class="c-unread-indicator"></div>
        {{/if}}
      
    */
    {
      "id": "HdYc2d7e",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showUrgent\"]],[[[1,\"      \"],[10,0],[14,0,\"c-unread-indicator -urgent\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"c-unread-indicator__number\"],[12],[1,\"\\n          \"],[1,[30,0,[\"urgentBadgeCount\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"showUnread\"]],[[[1,\"      \"],[10,0],[14,0,\"c-unread-indicator\"],[12],[13],[1,\"\\n    \"]],[]],null]],[]]],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/footer/unread-indicator.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FooterUnreadIndicator;
});