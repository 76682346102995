define("discourse/plugins/chat/discourse/components/chat/message/avatar", ["exports", "discourse/plugins/chat/discourse/components/chat-emoji-avatar", "discourse/plugins/chat/discourse/components/chat-user-avatar", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _chatEmojiAvatar, _chatUserAvatar, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Avatar = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="chat-message-avatar">
      {{#if @message.chatWebhookEvent.emoji}}
        <ChatEmojiAvatar @emoji={{@message.chatWebhookEvent.emoji}} />
      {{else}}
        <ChatUserAvatar @user={{@message.user}} @avatarSize="medium" />
      {{/if}}
    </div>
  
  */
  {
    "id": "QD63fWpw",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"chat-message-avatar\"],[12],[1,\"\\n\"],[41,[30,1,[\"chatWebhookEvent\",\"emoji\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@emoji\"],[[30,1,[\"chatWebhookEvent\",\"emoji\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[32,1],null,[[\"@user\",\"@avatarSize\"],[[30,1,[\"user\"]],\"medium\"]],null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[\"@message\"],false,[\"if\"]]",
    "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message/avatar.js",
    "scope": () => [_chatEmojiAvatar.default, _chatUserAvatar.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "avatar:Avatar"));
  var _default = _exports.default = Avatar;
});