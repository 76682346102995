define("discourse/plugins/chat/discourse/components/chat/drawer-routes/channel-thread", ["exports", "@glimmer/component", "@ember/helper", "@ember/service", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/chat-thread", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _service, _discourseI18n, _navbar, _chatThread, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatDrawerRoutesChannelThread extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chatHistory", [_service.service]))();
    #chatHistory = (() => (dt7948.i(this, "chatHistory"), void 0))();
    get backButton() {
      const link = {
        models: this.args.model?.channel?.routeModels
      };
      if (this.chatHistory.previousRoute?.name === "chat.channel.threads") {
        link.title = (0, _discourseI18n.i18n)("chat.return_to_threads_list");
        link.route = "chat.channel.threads";
      } else if (this.chatHistory.previousRoute?.name === "chat.threads") {
        link.title = (0, _discourseI18n.i18n)("chat.my_threads.title");
        link.route = "chat.threads";
        link.models = [];
      } else {
        link.title = (0, _discourseI18n.i18n)("chat.return_to_channel");
        link.route = "chat.channel";
      }
      return link;
    }
    get threadTitle() {
      return this.args.model?.channel?.activeThread?.title ?? (0, _discourseI18n.i18n)("chat.thread.label");
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="c-drawer-routes --channel-thread">
          {{#if @model.channel}}
            <Navbar
              @onClick={{this.chat.toggleDrawer}}
              @showFullTitle={{this.showfullTitle}}
              as |navbar|
            >
              <navbar.BackButton
                @title={{this.backButton.title}}
                @route={{this.backButton.route}}
                @routeModels={{this.backButton.models}}
              />
              <navbar.Title @title={{this.threadTitle}} @icon="discourse-threads" />
              <navbar.Actions as |a|>
                <a.ToggleDrawerButton />
                <a.FullPageButton />
                <a.CloseDrawerButton />
              </navbar.Actions>
            </Navbar>
    
            {{#if this.chatStateManager.isDrawerExpanded}}
              <div class="chat-drawer-content">
                {{#each (array @model.thread) as |thread|}}
                  <ChatThread
                    @thread={{thread}}
                    @targetMessageId={{@params.messageId}}
                  />
                {{/each}}
              </div>
            {{/if}}
          {{/if}}
        </div>
      
    */
    {
      "id": "VKPbvX7P",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-drawer-routes --channel-thread\"],[12],[1,\"\\n\"],[41,[30,1,[\"channel\"]],[[[1,\"        \"],[8,[32,0],null,[[\"@onClick\",\"@showFullTitle\"],[[30,0,[\"chat\",\"toggleDrawer\"]],[30,0,[\"showfullTitle\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[30,2,[\"BackButton\"]],null,[[\"@title\",\"@route\",\"@routeModels\"],[[30,0,[\"backButton\",\"title\"]],[30,0,[\"backButton\",\"route\"]],[30,0,[\"backButton\",\"models\"]]]],null],[1,\"\\n          \"],[8,[30,2,[\"Title\"]],null,[[\"@title\",\"@icon\"],[[30,0,[\"threadTitle\"]],\"discourse-threads\"]],null],[1,\"\\n          \"],[8,[30,2,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[30,3,[\"ToggleDrawerButton\"]],null,null,null],[1,\"\\n            \"],[8,[30,3,[\"FullPageButton\"]],null,null,null],[1,\"\\n            \"],[8,[30,3,[\"CloseDrawerButton\"]],null,null,null],[1,\"\\n          \"]],[3]]]]],[1,\"\\n        \"]],[2]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"          \"],[10,0],[14,0,\"chat-drawer-content\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[28,[32,1],[[30,1,[\"thread\"]]],null]],null]],null],null,[[[1,\"              \"],[8,[32,2],null,[[\"@thread\",\"@targetMessageId\"],[[30,4],[30,5,[\"messageId\"]]]],null],[1,\"\\n\"]],[4]],null],[1,\"          \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@model\",\"navbar\",\"a\",\"thread\",\"@params\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/drawer-routes/channel-thread.js",
      "scope": () => [_navbar.default, _helper.array, _chatThread.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatDrawerRoutesChannelThread;
});